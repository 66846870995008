import React, { useEffect } from 'react';
import Sidebar from '../Sidebar';
import styled from 'styled-components';
import ProjectList from './ProjectListPage';
import { Route, withRouter } from 'react-router-dom';
import ProjectPage from './ProjectPage';
import SettingsPage from './SettingsPage';
import YcDemoPage from './YcDemoPage';
import ModelReport from './ModelReport';
import SimulationPage from './SimulationPage';
import { fetchProjects } from '../redux/actions';
import { fetchSubscriptionInfo } from '../redux/authActions';
import { connect } from 'react-redux';
import { find, get } from 'lodash';
import DataSetListPage from './DataSetListPage';
import UpstartSimulationPage from './UpstartSimulationPage';
import PricingView from '../checkout-components/PricingView';
import CheckoutForm from '../checkout-components/CheckoutForm';
import { isBefore } from 'date-fns';
import { PRICING_FLOWS } from '../checkout-components/SubscriptionUtils';

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    _fetchProjects: () => dispatch(fetchProjects()),
    _fetchSubscriptionInfo: () => dispatch(fetchSubscriptionInfo()),
  };
};

const Page = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
`;
const Content = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
`;

const pathConfig = {
  '/': { component: ProjectList, exact: true },
  '/ycdemo': { component: YcDemoPage },
  '/datasets': { component: DataSetListPage },
  '/projects/:projectId/': { component: ProjectPage, exact: true },
  '/projects/:projectId/:showStep': { component: ProjectPage, exact: true },
  '/projects/:projectId/ml/report': { component: ModelReport },
  '/settings': { component: SettingsPage },
  '/simulation/:projectId/:modelId/:simulationId': {
    component: SimulationPage,
  },
  '/simulation-upstart/:projectId/:modelId/:simulationId': {
    component: UpstartSimulationPage,
  },
  '/checkout': { component: CheckoutForm },
};

const paths = Object.keys(pathConfig);
const NoSidebarPathPrefixes = ['/simulation'];

const SidebarRouteRender = ({ location }) => {
  const noSidebarPath = find(NoSidebarPathPrefixes, function (path) {
    return location.pathname.includes(path);
  });
  if (noSidebarPath === undefined) {
    if (location.pathname.startsWith('/projects/')) {
      return <Sidebar asDrawer />;
    } else {
      return <Sidebar />;
    }
  } else {
    return null;
  }
};

const Home = ({ user, _fetchProjects, _fetchSubscriptionInfo }) => {
  useEffect(() => {
    _fetchProjects();
    _fetchSubscriptionInfo();

    window.Intercom('boot', {
      app_id: 'hx86s642',
      email: user.email,
      name: user.name,
      created_at: user.created_time,
    });
    // eslint-disable-next-line
  }, []);

  const routes = paths.map((path) => (
    <Route key={path} {...pathConfig[path]} path={path} />
  ));

  const prePlanAccount = isBefore(
    new Date(user.created_time),
    new Date('June 15, 2020')
  );

  return (
    <Page>
      {get(user, 'subscription.id') === null &&
        !prePlanAccount &&
        !get(user, 'subscription.feature_flags.galileo') && (
          <PricingView
            pricingFlow={PRICING_FLOWS.startFreeTrial}
            showModal={true}
            onClose={null}
          />
        )}
      <Route render={SidebarRouteRender} />
      <Content>{routes}</Content>
    </Page>
  );
};

const StatefulHomePage = connect(mapStateToProps, mapDispatchToProps)(Home);

export default withRouter((props) => <StatefulHomePage {...props} />);
